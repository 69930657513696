// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-certification-js": () => import("./../../../src/pages/certification.js" /* webpackChunkName: "component---src-pages-certification-js" */),
  "component---src-pages-fair-play-js": () => import("./../../../src/pages/fair-play.js" /* webpackChunkName: "component---src-pages-fair-play-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-howtoplay-js": () => import("./../../../src/pages/howtoplay.js" /* webpackChunkName: "component---src-pages-howtoplay-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-responsible-gaming-js": () => import("./../../../src/pages/responsible-gaming.js" /* webpackChunkName: "component---src-pages-responsible-gaming-js" */),
  "component---src-pages-terms-of-service-terms-js": () => import("./../../../src/pages/terms-of-service-terms.js" /* webpackChunkName: "component---src-pages-terms-of-service-terms-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

